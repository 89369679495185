import { useFloating, useDismiss, useInteractions, FloatingOverlay as FloatingOverlayBase } from '@floating-ui/react'
import { useSpring, animated } from '@react-spring/web'
import { useDrag } from '@use-gesture/react'
import { useQuery } from '@tanstack/react-query'
import { useElementSize } from '@kaliber/use-element-size'
import { useQueryString } from '@kaliber/sanity-routing/queryString'

import { useLanguage, useTranslate } from '/machinery/I18n'
import { useMediaQuery } from '/machinery/useCachingMediaQuery'
import { useDebouncedValue } from '/machinery/useDebounced'
import { useSearchContext } from '/machinery/SearchContext'
import { ensureArray, ensureBoolean } from '/machinery/ensure'
import { pushToDataLayer, trackInteraction } from '/machinery/tracking/pushToDataLayer'

import { Icon } from '/features/buildingBlocks/Icon'
import { ImageCover } from '/features/buildingBlocks/Image'
import { Logo } from '/features/regionArticles/Logo'
import { InputText } from '/features/buildingBlocks/Input'
import { TagButton } from '/features/buildingBlocks/TagButton'
import { DrawerArticleLinkSearch } from '/features/regionArticles/deckToc/DrawerArticleLink'
import { LoaderCircleGray } from '/features/pageOnly/Loader'
import { getEsEditionTags, getEsSearchResult, getEsSearchResultWithinAndNotWithinRegion } from '/features/search/requests'
import { Checkbox } from '/features/buildingBlocks/Checkbox'

import mediaStyles from '/cssGlobal/media.css'
import styles from './Search.css'

import arrowRight from '/images/icons/arrow-right.raw.svg'
import searchIcon from '/images/icons/search.raw.svg'
import markIcon from '/images/icons/mark.raw.svg'

export function Search({ isOpen, onClose, coverImage, layoutClassName = undefined }) {
  const { refs, getFloatingProps } = useFloatingProps({ isOpen, onClose })

  return (
    <div aria-hidden={!isOpen} className={cx(styles.component, layoutClassName)}>
      <FloatingOverlayBase lockScroll={isOpen} className={cx(styles.floatingOverlay, isOpen && styles.overlayActive)}>
        <SearchDrawer
          floatingProps={getFloatingProps()}
          layoutClassName={styles.drawerLayout}
          {...{ isOpen, refs, onClose, coverImage }}
        />
      </FloatingOverlayBase>
    </div>
  )
}

export function SearchCanonical({ isOpen, onClose, coverImage = getCanonicalCoverImage(), layoutClassName = undefined }) {
  const { refs, getFloatingProps } = useFloatingProps({ isOpen, onClose })

  return (
    <div aria-hidden={!isOpen} className={cx(styles.componentCanonical, layoutClassName)}>
      <FloatingOverlayBase lockScroll={isOpen} className={cx(styles.floatingOverlay, isOpen && styles.overlayActive)}>
        <SearchDrawerCanonical
          floatingProps={getFloatingProps()}
          layoutClassName={styles.drawerLayout}
          {...{ isOpen, refs, onClose, coverImage }}
        />
      </FloatingOverlayBase>
    </div>
  )
}

function SearchDrawer({ isOpen, floatingProps, onClose, coverImage, layoutClassName = undefined }) {
  const { __ } = useTranslate()

  const [{ tags, searchAllIssues }, setQueryString] = useSearchQueryParams()
  const editionTags = useTags()
  const { search, results, status } = useArticles()
  const { withinRegion } = results
  const { isFiltering } = status
  const { issue: currentIssue } = useSearchContext()

  return (
    <SearchDrawerWrapper {...{ isOpen, onClose, floatingProps, layoutClassName }}>
      <SearchHeader
        search={search.value}
        onSearchChange={handleSearchChange}
        layoutClassName={styles.headerLayout}
        {...{ onClose, coverImage }}
      />

      {currentIssue && (
        <div className={styles.checkboxContainer}>
          <Checkbox
            name='searchAllIssues'
            checked={searchAllIssues}
            dataX='seach-all-issue-checkbox'
            label={__`show-all-issues`}
            onChange={handleSearchAllIsssuesClick}
          />

          {Boolean(withinRegion.articles?.length) && (
            <p className={styles.resultCountElement}>{withinRegion.articles.length} {__(withinRegion.articles.length)`number-of-articles-found`}</p>
          )}
        </div>
      )}

      <Tags {...{ isFiltering, tags, editionTags }} />

      <Results {...{ search, results, status }} />
    </SearchDrawerWrapper>
  )

  function handleSearchAllIsssuesClick() {
    trackInteraction({
      action: 'clicked',
      type: 'click',
      title: 'search_all_editions'
    })
    setQueryString(x => ({ ...x, searchAllIssues: ensureBoolean(x.searchAllIssues) ? undefined : true }))
  }

  function handleSearchChange(value) {
    search.setValue(value)
  }
}

function SearchDrawerWrapper({ isOpen, onClose, floatingProps, children, layoutClassName }) {
  const { style, elementRef } = useDrawerStyle({ isOpen })

  const bind = useDrag(
    ({ swipe: [swipeX], movement: [movementX] }) => {
      if (swipeX === 1 || movementX > 150) onClose()
    }, { axis: 'x' }
  )

  return (
    <animated.div
      ref={elementRef}
      className={cx(styles.componentDrawerWrapper, layoutClassName)}
      {...{ style, ...floatingProps, ...bind() }}
    >
      {children}
    </animated.div>
  )
}

function SearchHeader({ search, onSearchChange, onClose, coverImage = undefined, layoutClassName = undefined }) {
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd)

  return (
    <header className={cx(styles.componentHeader, layoutClassName)}>
      {coverImage && <div className={styles.imageContainer}>
        <ImageCover
          image={coverImage}
          aspectRatio={isViewportMd ? 16 / 9 : 9 / 16}
          layoutClassName={styles.imageLayout}
        />
      </div>}

      <div className={styles.headerWrapper}>
        <div className={styles.headerContentContainer}>
          <button onClick={onClose} data-x='click-to-close-search' className={styles.backButton}>
            <Icon icon={arrowRight} layoutClassName={styles.iconLayout} />
          </button>

          <Logo layoutClassName={styles.logoLayout} />

          <SearchForm layoutClassName={styles.formLayout} {...{ search, onSearchChange }} />
        </div>
      </div>
    </header>
  )
}

function Results({ results, status, search }) {
  const { __ } = useTranslate()
  const { isFetching } = status
  const { withinRegion, notWithinRegion } = results
  const noArticlesFound = search.value.length && !withinRegion.articles?.length && !notWithinRegion.articles?.length && !isFetching

  return (
    <>
      <ResultsStatus {...{ isFetching, noArticlesFound }} />
      <ul className={styles.resultsContainer}>
        {withinRegion?.articles?.map((item, i) => (
          <DrawerArticleLinkSearch key={i} {...{ item }} />
        ))}
        {Boolean(notWithinRegion.total) && <h3 className={styles.differentRegionsHeading}>{__`from-different-regions`}</h3>}
        {notWithinRegion?.articles?.map((item, i) => (
          <DrawerArticleLinkSearch key={i} {...{ item }} />
        ))}
      </ul>
    </>
  )
}

function ResultsStatus({ isFetching, noArticlesFound }) {
  const { __ } = useTranslate()

  return (
    <>
      {noArticlesFound && (
        <div className={styles.drawerContainer}>
          <SearchHeading text={`${__`no-search-results`}:`} />
        </div>
      )}

      {isFetching && (
        <div className={styles.drawerContainer}>
          <LoaderCircleGray layoutClassName={styles.loaderLayout} />
        </div>
      )}
    </>
  )
}

function SearchDrawerCanonical({ isOpen, floatingProps, onClose, coverImage = undefined, layoutClassName = undefined }) {
  const [{ tags, searchAllIssues }, setQueryString] = useSearchQueryParams()
  const editionTags = useTags()
  const { __ } = useTranslate()

  const { search, results, status } = useArticlesCanonical()
  const { articles } = results
  const { isFiltering } = status
  const { issue: currentIssue } = useSearchContext()

  return (
    <SearchDrawerWrapper {...{ isOpen, onClose, floatingProps, layoutClassName }}>
      <SearchHeader search={search.value} onSearchChange={handleSearchChange} layoutClassName={styles.headerLayout} {...{ onClose, coverImage }} />

      {currentIssue && (
        <div className={styles.checkboxContainer}>
          <Checkbox
            name='searchAllIssues'
            checked={searchAllIssues}
            dataX='seach-all-issue-checkbox'
            label={__`show-all-issues`}
            onChange={handleSearchAllIsssuesClick}
          />

          {Boolean(articles?.length) && (
            <p className={styles.resultCountElement}>{articles.length} {__(articles.length)`number-of-articles-found`}</p>
          )}
        </div>
      )}

      <Tags {...{ isFiltering, tags, editionTags }} />

      <ResultsCanonical {...{ search, results, status }} />
    </SearchDrawerWrapper>
  )

  function handleSearchAllIsssuesClick() {
    trackInteraction({
      action: 'clicked',
      type: 'click',
      title: 'search_all_editions'
    })
    setQueryString(x => ({ ...x, searchAllIssues: ensureBoolean(x.searchAllIssues) ? undefined : true }))
  }

  function handleSearchChange(value) {
    search.setValue(value)
  }
}

function Tags({ isFiltering, tags, editionTags }) {
  return (
    <>
      {tags.length > 0 && <SelectedSuggestion activeTags={tags.map(x => editionTags.find(tag => tag.value === x)).filter(Boolean)} />}
      {!isFiltering && <SearchSuggestions tags={editionTags} />}
    </>
  )
}

function ResultsCanonical({ results, status, search }) {
  const { isFetching } = status
  const { articles } = results
  const noArticlesFound = search.value.length && !articles?.length && !isFetching

  return (
    <>
      <ResultsStatus {...{ isFetching, noArticlesFound }} />
      <ul className={styles.resultsContainer}>
        {articles?.map((item, i) => (
          <DrawerArticleLinkSearch key={i} {...{ item }} />
        ))}
      </ul>
    </>
  )
}

function SearchForm({ search, onSearchChange, layoutClassName = undefined }) {
  const { __ } = useTranslate()
  const inputRef = React.useRef(null)

  return (
    <div className={cx(styles.componentForm, layoutClassName)}>
      <Icon icon={searchIcon} layoutClassName={styles.iconLayout} />

      <div ref={inputRef} className={styles.inputContainer}>
        <InputText
          hasIcon
          placeholder={`${__`i-am-searching-for`}...`}
          maxLength={64}
          value={search}
          onChange={handleChange}
          layoutClassName={styles.inputLayout}
        />
      </div>

      {Boolean(search.length) && (
        <button onClick={handleDeletePrompt} className={styles.deletePromptButton}>
          <Icon icon={markIcon} layoutClassName={styles.iconLayout} />
        </button>
      )}
    </div>
  )

  function handleChange(e) {
    onSearchChange(e.currentTarget.value)
  }

  function handleDeletePrompt() {
    const input = inputRef.current?.childNodes[0]

    onSearchChange('')
    input.focus()
  }
}

function SearchSuggestions({ tags, layoutClassName = undefined }) {
  const { __ } = useTranslate()

  return (
    <div className={cx(styles.componentSuggestions, layoutClassName)}>
      <SearchHeading text={`${__`suggestions`}:`} />

      <ul className={styles.suggestionsList}>
        {tags.slice(0, 5).map((item, i) => (
          <Suggestion key={i} {...{ item }} />
        ))}
      </ul>
    </div>
  )
}

function SearchHeading({ text }) {
  return <h3 className={styles.componentHeading}>{text}</h3>
}

function Suggestion({ item }) {
  const [, setQueryString] = useQueryString()
  const { buttonId } = item

  return (
    <TagButton onClick={handleClick} label={`${item.label} (${item.count})`} dataX='click-to-select-suggested-search-tag' {...{ buttonId }} />
  )

  function handleClick() {
    setQueryString(x => ({ ...x, tags: [item.value] }))
  }
}

function SelectedSuggestion({ activeTags }) {
  const [, setQueryString] = useQueryString()

  return (
    <ul className={styles.componentSelectedSuggestion}>
      {activeTags.map((tag, i) => (
        <TagButton key={i} buttonId={tag.buttonId} onClick={handleClick} label={tag.label} icon={markIcon} dataX='click-to-remove-suggested-search-tag' layoutClassName={styles.tagLayout} />
      ))}
    </ul>
  )

  function handleClick() {
    setQueryString(x => ({ ...x, tags: undefined }))
  }
}

function useDrawerStyle({ isOpen }) {
  const { size: { width }, ref: elementRef } = useElementSize()

  const [style, api] = useSpring(() => ({
    right: '-100%',
  }))

  React.useEffect(
    () => {
      api.start({
        right: isOpen ? '0px' : `-${width}px`
      })
    },
    [isOpen, api, width]
  )

  return { style, elementRef }
}

function useFloatingProps({ isOpen, onClose }) {
  const { refs, context } = useFloating({
    open: isOpen,
    onOpenChange: open => {
      if (!open) onClose()
    }
  })

  const dismiss = useDismiss(context, { capture: false })
  const { getFloatingProps } = useInteractions([dismiss])

  return { refs, getFloatingProps }
}

function useTags() {
  const language = useLanguage()
  const { bank, region, issue: rawIssue } = useSearchContext() || {}
  const [{ searchAllIssues }] = useSearchQueryParams()

  const issue = searchAllIssues ? null : rawIssue
  const { data } = useQuery({
    queryKey: [language, bank, region, issue],
    queryFn: () => getEsEditionTags({ language, bank, issue, region }),
    initialData: {},
    refetchOnWindowFocus: false
  })

  const { tags = [] } = data

  return tags.map((x, buttonId) => ({ ...x, buttonId }))
}

function useArticles() {
  const language = useLanguage()
  const [search, setSearch] = React.useState('')
  const [{ tags, searchAllIssues }] = useSearchQueryParams()
  const { bank, region, issue: rawIssue } = useSearchContext() || {}
  const debouncedSearch = useDebouncedValue(search)

  const issue = searchAllIssues ? null : rawIssue

  const placeholderDataRef = React.useRef({ withinRegion: { articles: [], total: 0 }, notWithinRegion: { articles: [], total: 0 } })

  const { data, isError, isFetching } = useQuery({
    queryKey: [debouncedSearch, language, bank, region, issue, tags].filter(Boolean),
    queryFn: async () => {
      const data = await getSearchResult({ searchString: debouncedSearch, bank, region, issue, language, tags })
      placeholderDataRef.current = data
      return data
    },
    placeholderData: placeholderDataRef.current,
    refetchOnWindowFocus: false
  })

  const isFiltering = debouncedSearch || tags.length

  return {
    search: {
      value: search,
      setValue: setSearch
    },
    results: data,
    status: {
      isFiltering,
      isError,
      isFetching
    }
  }

  async function getSearchResult({ searchString, bank, region, issue, tags, language }) {
    if (!searchString && tags.length === 0) return { withinRegion: {}, notWithinRegion: {} }

    const { withinRegion, notWithinRegion } = await getEsSearchResultWithinAndNotWithinRegion({ searchString, bank, region, issue, tags, language, size: 999 })

    filterChangedEvent({ tags, searchString, total: withinRegion.total })

    return { withinRegion, notWithinRegion }
  }
}

function useArticlesCanonical() {
  const language = useLanguage()
  const [search, setSearch] = React.useState('')
  const [{ tags, searchAllIssues }] = useSearchQueryParams()
  const debouncedSearch = useDebouncedValue(search)
  const { issue: rawIssue } = useSearchContext()

  const issue = searchAllIssues ? null : rawIssue

  const placeholderDataRef = React.useRef({ articles: [], total: 0 })

  const { data, isError, isFetching } = useQuery({
    queryKey: [debouncedSearch, language, tags, issue].filter(Boolean),
    queryFn: async () => {
      const data = await getSearchResult({ searchString: debouncedSearch, language, issue, tags })
      placeholderDataRef.current = data
      return data
    },
    placeholderData: placeholderDataRef.current,
    refetchOnWindowFocus: false
  })

  const isFiltering = debouncedSearch || tags.length

  return {
    search: {
      value: search,
      setValue: setSearch
    },
    results: data,
    status: {
      isFiltering,
      isError,
      isFetching
    }
  }

  async function getSearchResult({ searchString, tags, issue, language }) {
    if (!searchString && tags.length === 0) return { articles: [], total: 0 }

    const { articles, total } = await getEsSearchResult({ searchString, tags, issue, language, size: 999 })

    filterChangedEvent({ tags, searchString, total })

    return { articles, total }
  }
}


/**
 * @returns {[{tags: string[], searchAllIssues: boolean}, (any) => void]}
 */
function useSearchQueryParams() {
  const [{ tags = [], searchAllIssues = false }, setQueryString] = useQueryString()
  return [
    {
      tags: ensureArray(tags),
      searchAllIssues: ensureBoolean(searchAllIssues)
    },
    setQueryString
  ]
}

function filterChangedEvent({ tags, searchString, total }) {
  pushToDataLayer({
    event: 'filter_changed',
    metadata: {
      filter: {
        tag: tags?.[0] ? tags[0] : null,
        searchterm: searchString?.length ? searchString : null,
        maxresults: total
      }
    }
  })
}

function getCanonicalCoverImage() {
  return {
    asset: { _ref: 'image-9c280c718d4d0c8eeb68cf3711ba53bd-1667x2500-webp' },
    metadata: {
      lqip: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAeCAMAAAAbzM5ZAAAABlBMVEUuIxtAMiehWDQZAAAACXBIWXMAAAPoAAAD6AG1e1JrAAAAQUlEQVR4nN2MiQ0AIAgDuf2XNryCTqA1KfZoEPlf3JkDaDbMKNCRff1J0UrMJW4kqjVhbWaxI1PMuLQ36tkZegUure4BGlW2hmkAAAAASUVORK5CYII='
    },
    hotspot: { x: 0.468160336186731, y: 0.3844797178130511, width: 1, height: 1 },
    crop: { right: 0, top: 0, left: 0, bottom: 0 }
  }
}
