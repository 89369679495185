export function ensureArray(value) {
  return [].concat(value).filter(Boolean)
}

export function ensureInteger(input, fallback) {
  const value = Number.parseInt(input, 10)
  if (isNaN(value)) return fallback
  return value
}

export function ensureBoolean(value) {
  if (!value) return false
  if (value === 'false') return false
  if (value === '0') return false
  return true
}
