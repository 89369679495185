import { useLocation } from '@kaliber/routing'

import { routeMap } from '/routeMap'
import { useLanguage, useTranslate } from '/machinery/I18n'

import { useArticleInfo } from '/features/regionArticles/deckToc/useArticleInfo'
import { ImageCover } from '/features/buildingBlocks/Image'
import { Icon } from '/features/buildingBlocks/Icon'

import styles from './DrawerArticleLink.css'

import checkmarkIcon from '/images/icons/check.raw.svg'

export function DrawerArticleLink({ item }) {
  const { pathname } = useLocation()
  const language = useLanguage()
  const { read } = item
  const isActiveFn = ({ issue, bankcode, rubricSlug }) => pathname === routeMap.app.issue.region.article({ issue, bankcode, language, rubricSlug })
  const { hero, title, rubric, isHighlighted, isActive, bankcode, issue, rubricSlug } = useArticleInfo(item, { isActiveFn })

  const url = routeMap.app.issue.region.article({ issue, bankcode, language, rubricSlug })

  return (
    isHighlighted ? (
      <ItemHighlighted {...{ hero, rubric, read, isActive }}>
        <Link layoutClassName={styles.clickableComponentLayout} {...{ title, url, read }} />
      </ItemHighlighted>
    ) : (
      <ItemStandard {...{ rubric, read, isActive }}>
        <Link layoutClassName={styles.clickableComponentLayout} {...{ title, url, read }} />
      </ItemStandard>
    )
  )
}

export function DrawerArticleLinkButton({ item, onClick }) {
  const { hash } = useLocation()
  const { read } = item

  const isActiveFn = ({ rubricSlug }) => hash === `#${rubricSlug}`
  const { hero, title, rubric, isHighlighted, isActive } = useArticleInfo(item, { isActiveFn })

  return (
    isHighlighted ? (
      <ItemHighlighted {...{ hero, rubric, read, isActive }}>
        <Button layoutClassName={styles.clickableComponentLayout} {...{ title, onClick }} />
      </ItemHighlighted>
    ) : (
      <ItemStandard {...{ rubric, read, isActive }}>
        <Button layoutClassName={styles.clickableComponentLayout} {...{ title, onClick }} />
      </ItemStandard>
    )
  )
}

export function DrawerArticleLinkSearch({ item }) {
  const { pathname } = useLocation()
  const language = useLanguage()

  const isActiveFn = ({ issue, bankcode, rubricSlug }) => (issue && bankcode) ? pathname === routeMap.app.issue.region.article({ issue, bankcode, language, rubricSlug }) : false
  const { title, rubric, isActive } = useArticleInfo(item, { isActiveFn })

  const url = routeMap.app.articles.single({ language, idOrIds: item.id })

  return (
    <ItemStandard {...{ rubric, isActive }}>
      <Link layoutClassName={styles.clickableComponentLayout} {...{ title, url }} />
    </ItemStandard>
  )
}

function ItemStandard({ rubric, isActive, children, read = undefined }) {
  return (
    <li className={styles.componentItemStandard}>
      <DeckItemContentLink layoutClassName={styles.deckItemContentLayout} {...{ rubric, read, isActive, children }} />
    </li>
  )
}

function ItemHighlighted({ hero, rubric, read, isActive, children }) {
  return (
    <li className={styles.componentItemHighlighted}>
      <DeckItemContentLink layoutClassName={styles.deckItemContentLayout} {...{ rubric, read, isActive, children }} />
      {hero.image && <HighlightedImage image={hero.image} layoutClassName={styles.deckItemImageLayout} />}
    </li>
  )
}

function DeckItemContentLink({ rubric, read, isActive, children, layoutClassName = undefined }) {
  const { __ } = useTranslate()

  return (
    <div className={cx(styles.componentDeckItemContentLink, read && styles.read, layoutClassName)}>
      <div className={styles.rubricContainer}>
        <div className={styles.rubricText}>
          <span>{rubric}</span>
          {read && <Icon icon={checkmarkIcon} layoutClassName={styles.iconLayout} />}
        </div>
        {isActive && <span className={styles.activeArticle}>{__`here-you-are`}</span>}
      </div>
      {children}
    </div>
  )
}

function Link({ title, url, layoutClassName = undefined }) {
  return (
    <a href={url} data-x='link-to-detail' className={cx(styles.componentLink, layoutClassName)}>
      {title}
    </a>
  )
}

function Button({ title, onClick, layoutClassName = undefined }) {
  return (
    <button data-x='link-to-detail' onClick={handleClick} className={cx(styles.componentButton, layoutClassName)}>
      {title}
    </button>
  )

  function handleClick(e) {
    if (!shouldNavigate(e)) return

    e.preventDefault()
    onClick()
  }
}

function shouldNavigate(e) {
  return (
    !e.defaultPrevented &&
    e.button === 0 &&
    !(e.metaKey || e.altKey || e.ctrlKey || e.shiftKey)
  )
}

function HighlightedImage({ image, layoutClassName }) {
  return (
    <div className={cx(styles.componentHighlightedImage, layoutClassName)}>
      <ImageCover aspectRatio={1 / 1} layoutClassName={styles.imageLayout} {...{ image }} />
    </div>
  )
}
