import { Icon } from '/features/buildingBlocks/Icon'

import styles from './Checkbox.css'

import iconCheckSharp from '/images/icons/check-sharp.raw.svg'

export function Checkbox({ name, checked, label, dataX, onChange, layoutClassName = undefined }) {
  return (
    <div className={cx(styles.component, layoutClassName)}>
      <label htmlFor={name} className={styles.checkboxLabel}>{label}</label>

      <input
        id={name}
        type='checkbox'
        data-x={dataX}
        value={checked}
        defaultChecked={checked}
        className={styles.inputCheckbox}
        {...{ name, onChange }}
      />

      <div className={cx(styles.checkbox, checked && styles.isChecked)}>
        <Icon icon={iconCheckSharp} />
      </div>
    </div>
  )
}
