/** @type {React.Context<{region: string, issue: string, bank: { woodwing?: string, regio?: string }}>} */
const searchContext = React.createContext(null)

export function useSearchContext() {
  return React.useContext(searchContext)
}

export function SearchContextProvider({  doc = undefined, issue = undefined, regionData = undefined, children }) {
  const regio = regionData?.bank?.woodwing || regionData?.bank?.regio

  const data = {
    region: regio ? `Rabo en Co ${regio}` : doc?.publicationLevelName,
    issue,
    bank: regionData?.bank
  }

  return <searchContext.Provider value={data} {...{ children }} />
}
